import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import titleApp from '../../services/title';
import SMM from '../../components/section-main-main';
import SMC from '../../components/section-main-courses';
import SMF from '../../components/section-main-feedback';
import SLC from '../section-landing-cost';
import SectionLandingFutures from '../section-landing-futures';




const HomePage = () => {

  useEffect(() => {
    document.title = `${titleApp()}`
    document.querySelector('footer').style = 'background: transparrent';
    document.body.style = 'background: #F7F7F5;';
  }, []);

    return (
      <>
        <SMM />
        <SMC />
        <SectionLandingFutures />
      </>
    );
};

export default observer(HomePage);