import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logotype.png';
import { Link } from 'react-router-dom';
import Hero from '../assets/reviews.png';
import Selected from '../assets/list_selected.png';
import List from '../assets/list.png';
import axios from 'axios';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';


const SLF = () => {

  const [practics, setPractics] = useState(true);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');      
  const [model, setModel] = useState(0);
  const [typePayment, setTypePayment] = useState(1);
  const courseName = '«Мастер-фасилитатор»';
  const currentYear = new Date().getFullYear();
  const OneSum = 60000000;
  const OneSumDecorative = '600 000';
  const OneSumDeposit = 2500000;
  const OneSumDepositDecorative = '25 000';
  const TwoSum = 20000000;
  const TwoSumDecorative = '200 000';
  const ThreeSum = 22000000;
  const ThreeSumDecorative = '220 000';
  const [buyModalOne, setBuyModalOne] = useState(true);
  const [buyModalTwo, setBuyModalTwo] = useState(true);
  const [buyModalThree, setBuyModalThree] = useState(true);

  function ShowModalBuyOne() {
    setBuyModalOne(!buyModalOne);
    if(buyModalOne) document.getElementById("modalPurchaseOne").style.display = "block";
    else document.getElementById("modalPurchaseOne").style.display = "none";
  }
  function ShowModalBuyTwo() {
    setBuyModalTwo(!buyModalTwo);
    if(buyModalTwo) document.getElementById("modalPurchaseTwo").style.display = "block";
    else document.getElementById("modalPurchaseTwo").style.display = "none";
  }
  function ShowModalBuyThree() {
    setBuyModalThree(!buyModalThree);
    if(buyModalThree) document.getElementById("modalPurchaseThree").style.display = "block";
    else document.getElementById("modalPurchaseThree").style.display = "none";
  }
  function showpractics() {
    setPractics(!practics);
    if(practics) document.getElementById("footerdrop").style.display = "flex";
    else document.getElementById("footerdrop").style.display = "none";
  }
  function initPaymentOne() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    let purchaseOption;
    let itemAmount;
    let itemName;
    if (typePayment === 1) {
      itemAmount = OneSum;
      itemName = courseName + ' (Бессрочный доступ)';
      purchaseOption = 2;
    }
    if (typePayment === 2) {
      itemAmount = OneSumDeposit;
      itemName = courseName + ' (Бессрочный доступ) - депозит';
      purchaseOption = 1;
    }
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }
  function initPaymentTwo() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    let purchaseOption = 2;
    let itemAmount = TwoSum;
    let itemName = courseName + ' (Годовая лицензия)';
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }
  function initPaymentThree() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    let purchaseOption = 1;
    let itemAmount = ThreeSum;
    let itemName = courseName + ' (Рассрочка)';
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }

    return (
      <section className="desc-landing">
          <div id="modalPurchaseOne" className="modal-buy">
          <div className="modal-content">
              <span onClick={() => ShowModalBuyOne()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Мастер-фасилитатор»</h3>
              <hr className="modal-feedback-hr" />
              <p className="modal-title">Модель оплаты</p>
              { typePayment === 1 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment">Внести депозит</button>
              </div>
              : typePayment === 2 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment_alt">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment_alt">Внести депозит</button>
              </div>
              : null
              }
              { typePayment === 2 ?
                <div className='disclamer-payment-box'>
                  <p>Вы вносите депозит в размере 25 000 руб; остаток – 575 000. Оставшуюся сумму необходимо внести за 2 дня до начала курса</p>
                </div>
                : null
              }
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Мастер-фасилитатор»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>01.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>05.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{typePayment === 1 ? OneSumDecorative + ' ₽' : typePayment === 2 ? OneSumDepositDecorative + ' ₽' : 'Неизвестно'}</p>
                </div>
              </div>
              <button onClick={() => initPaymentOne()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
          <div id="modalPurchaseTwo" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuyTwo()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Мастер-фасилитатор»</h3>
              <hr className="modal-feedback-hr" />
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Мастер-фасилитатор»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>01.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>05.05.2022</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{TwoSumDecorative + ' ₽'}</p>
                </div>
              </div>
              <button onClick={() => initPaymentTwo()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
        <div className='desc-background'>
        </div>
        <div>
          <div className="desc-cards">
            <div className="desc-card">
              <h2 className='course-description-header'>
                <span>Сертификат даёт право</span> на применение данного метода без ограничения по количеству участников в группе
              </h2>
              <div className="course-description-slider">
                <p><span>Автор метода, вместе Сертификатом Мастера фасилитации метода «Сияющие Руки»</span> даёт право на применение данного метода без ограничения по количеству участников в группе, со всеми преимуществами метода.</p>
              </div>
              <div className="course-description-slider-alt">
                <p><span>Возможность применения и передачи инструментов метода «Сияющие Руки»</span> бессрочно, т.е. без необходимости повторного обучения или подтверждении сертификации.</p>
              </div>
              <div className="course-description-slider-alt2">
                <p><span>Таким образом, автор метода предоставляет право работы пожизненно,</span> при соблюдении корректного применения основ метода, соблюдение целостности его передачи практикам, основываясь на законе об авторском праве и соблюдении юридических законов РФ.</p>
              </div>
            </div>
          </div>
        </div>
        <h2 className='course-description-header'><span>Условия прохождения</span> курса</h2>
        <p className="course-description-text"> Для прохождения курса «Мастер фасилитации в методе «Сияющие Руки» и получения права его передачи необходимо выполнение следующих условий:</p>
        
        <div className='conditions-box'>
          <div className="conditions-box_item">
            <p className="conditions-box_item_text"><span>К курсу допускаются практики метода «Сияющие Руки»</span>, имеющие сертификат о:</p>
            <p className='conditions-box_item_text'>- Прохождении сертификационного курса «Его Величество Тело»</p>
            <p className='conditions-box_item_text'>- Практикам необходимо иметь опыт фасилитации не менее 10 курсов сессий по разным запросам своих или клиентов. Навыки в анализе наблюдении процессов. Именно на этом личном опыте будет происходить интеграция приобретенных навыков в материалы курса.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
            </svg>
          </div>
          <div className="conditions-box_item_alt">
            <p className="conditions-box_item_text_alt">К данному курсу Мастер фасилитации в методе «Сияющие Руки» приглашаются практики метода «Сияющие Руки» никогда не состоявшие на учёте в психоневрологических учреждений, <span>никогда не состоявшие на учёте в специализированных учреждений</span> в связи с алкогольной или наркотической зависимостями.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
            </svg>
          </div>
        </div>






        <div className="container">
          <h2 className="dark_header limit-header">Варианты <span> участия и стоимость</span></h2>
          <div className="cost-small-box">
            <div className='cost-small-item'>
              <h2 className="title-cost">600 000 руб.</h2>
              <div className='cost-small-item-box'>
                <p className="cost-small-item-box_text">Бессрочный доступ к материалам, обновлениям и преподаванию метода с сертификацией курса «Сияющие Руки»</p>
              </div>
              <a onClick={() => ShowModalBuyOne()}>
                <button className="circle-btn_fill circle-payment-btn-alt">
                  Оплатить
                </button>
              </a>
            </div>   
            <div className='cost-small-item'>
              <h2 className="title-cost">200 000 руб.</h2>
              <div className='cost-small-item-box'>
                <p className="cost-small-item-box_text-2">Возможность приобрести лицензию на один год</p>
              </div>
              <a onClick={() => ShowModalBuyTwo()}>
                <button className="circle-btn_fill circle-payment-btn-alt">
                  Оплатить
                </button>
              </a>
            </div>  
            <div className='cost-small-item'>
              <h2 className="title-cost">220 000 руб.</h2>
              <div className='cost-small-item-box'>
                <p className="cost-small-item-box_text-3">Беспроцентная рассрочка от банка Тинькофф на сумму 200 000 рублей</p>
              </div>
              <a onClick={() => errorNotify('Оплата в рассрочку временно недоступна!')}>
                <button className="circle-btn_fill circle-payment-btn-alt">
                  Оплатить
                </button>
              </a>
            </div>  

          </div>
          <div className="costs-group">
            <h2 className="title-cost">600 000 руб.</h2>
            <h2 className="title-cost">200 000 руб.</h2>
            <h2 className="title-cost">220 000 руб.</h2>
          </div>
          <div className="cost-desc-group">
            <div className="cost-description">
              <p className='cost-desc-text-1'>Бессрочный доступ к материалам, обновлениям и преподаванию метода с сертификацией курса «Сияющие Руки»</p>
            </div>
            <div className="cost-description-2">
              <p className='cost-desc-text-2'>Возможность приобрести лицензию на один год</p>
            </div>
            <div className="cost-description-3">
              <p className='cost-desc-text-3'>Беспроцентная рассрочка от банка Тинькофф на сумму 200 000 рублей</p>
            </div>
          </div>
          <div className="group_circle-btn-alt">
            <a onClick={() => ShowModalBuyOne()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a onClick={() => ShowModalBuyTwo()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a onClick={() => errorNotify('Оплата в рассрочку временно недоступна!')}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
          </div>
        </div>

        <footer>
        <div className="container">
          <div className="footer_block">
            <div className="footer_block-brand">
              <img className="footer_block-logotype" src={Logo} />
              <p>© {currentYear}, Елена Эра. Все права защищены</p>
            </div>
            <div className="sm-footer-info">
              <a>Расписание</a>
              <a onClick={() => showpractics()}>
                Практики
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </a>
              <div id="footerdrop">
                <Link className="footerdrop-item" to="/shining-hands">- Практики метода «Сияющие руки»</Link>
                <Link className="footerdrop-item" to="/">- Практики метода «Соединение миров»</Link>
              </div>
              <a>Мастера</a>
              <a>Книги</a>
              <a>Блог</a>
            </div>
            <div className="footer_block-info">
              <div className="footer_block-links">
                <Link to="/schedule">Расписание</Link>
                <div className="dropup">
                  <button className="dropupbtn">
                    Практики 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <div className="dropup-content">
                    <a href="#">Практики метода «Сияющие руки»</a>
                    <a href="#">Практики метода «Соединение миров» </a>
                  </div>
                </div>
                <a href="#3">Мастера</a>
                <a href="#3">Книги</a>
                <a href="#3">Блог</a>
              </div>
              <a className="footer_block-policy" href="#3">Политика конфиденциальонсти</a>
            </div>
          </div>
        </div>
      </footer>
      
      </section>
    );
};

export default observer(SLF);