import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import { Link } from 'react-router-dom';
import { ShowModalBuy } from './header';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';
import axios from 'axios';

const SMM = () => {

  const [buyModal, setBuyModal] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [surName, setSurName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  function ShowModalBuy() {
    setBuyModal(!buyModal);
    if(buyModal) document.getElementById("buyModal").style.display = "block";
    else document.getElementById("buyModal").style.display = "none";
  }

  function postTicket() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('typeCourse', "«Мастер-фасилитатор»")
    formData.append('ticketStatus', 1)
    axios.post(API_URL + '/t/coursecreate', formData)
    .then(res => {
      successNotify('Спасибо! Мы обязательно свяжемся с вами!');
      setFirstName('');
      setLastName('');
      setSurName('');
      setEmail('');
      setPhone('');
      ShowModalBuy();
    })
    .catch(err => errorNotify(err.response.data.message));
  }

    return (
      <section className="main" id="mainpage">
        <div id="buyModal" className="modal-buy">
          <div className="modal-content">
            <span onClick={() => ShowModalBuy()} className="close">&times;</span>
            <h3 className='modal-feedback-header'>Записаться</h3>
            <hr className="modal-feedback-hr" />
            <p className="modal-title">Контактные данные</p>
            <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
            <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
            <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
            <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
            <div className="card_buy-box">
              <div className='card_buy-confirm'>
                <p className='card_buy-confirm-text'>Курс:</p>
                <p className='card_buy-confirm-text'>«Мастер-фасилитатор»</p>
              </div>
            </div>
            <button onClick={() => postTicket()} className='modal-btn-submit'>Записаться</button>
          </div>
        </div>
        <div className="container">
          <p className="title-landing">Сертификационный курс</p>
          <h1 className="main_header">«Мастер-фасилитатор»</h1>
          <div className="main_text">
          <p>Курс для практиков метода «Сияющие Руки», сделавших свой выбор стать Мастером фасилитации (сопровождения)продолжительностью 5 дней.</p>
          <p>Целостный процесс интеграции уже приобретенных знаний, личного опыта, профессиональных навыков с возможностями технологии «Биоэнергетическая Интегративная Квантовая Фасилитация».</p>
          </div>
          <p className="special-landing">Оффлайн-формат (выезд)– 5 дней</p>
          <div className="group_circle-btn">
            <a onClick={() => ShowModalBuy()}>
              <button className="circle-btn">
                Записаться
              </button>
            </a>
          </div>
        </div>
      </section>
    );
};

export default observer(SMM);