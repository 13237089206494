import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logotype.png';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { API_URL } from '../http';

const SMC = () => {
  const [settings, setSettings] = useState([]);
  const [modalVideo, setModalVideo] = useState(true);
  function showModalVideo() {
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }
  function closeModalVideo() {
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }
  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setSettings(data);
  };


  useEffect(() => {
    getSettings();
  }, [setSettings]);
  return (
    <>
        <div id="myVideo" className="modal">
          <div className="modal-content-media">
            <span onClick={() => closeModalVideo()} className="close">&times;</span>
            <h3 className='modal-feedback-header' style={{marginBottom: '30px'}}>О курсе</h3>
            {settings.op_link_yt_3 !== '' ?
            <ReactPlayer
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}

                // Disable right click
                onContextMenu={e => e.preventDefault()}

                // Your props
                url={settings.op_link_yt_3}
                className="react-player"
                controls
                width="100%"
                height="460px"
            /> 
            :
            null}
          </div>
        </div>
    <section className="courses">
      <div className="container">
        <h2 className="dark_header">Цель курса:</h2>
        <div className="courses-block">
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Осознание ценностей собственного опыта, как специалиста помогающей практики и приобретение навыков эффективной коммуникации, понимания групповых динамик и предоставление участникам курсов метода «Сияющие Руки» психологически безопасное и максимально эффективное по качеству передаваемого материалов пространство.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Осознание себя, как уникальной личности помогающего практика, способного управлять своим эмоциональным состоянием, находятся в позиции наблюдения, без намерения воздействия или влияния на других людей.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Закрепить навык создавать с помощью алгоритма модели «Сияющей Сессии» энергетическое пространство для других людей, чтобы стать пространством исцеления сознания и развития творческого потенциала и интуитивных способностей.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Выработать экологические навыки обращения с различными групповыми динамиками, сохраняя свою эффективность как фасилитатора и активной мета-позицией</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Закрепление ранее полученных навыков энергетических сессий для групп участников.</div>
              </div>
            </div>
          </div>
          <div className="courses-card">
            <div className="courses-card_item">
              <div className="courses-card_content">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 0L9.52568 5.47432L15 7.5L9.52568 9.52568L7.5 15L5.47432 9.52568L0 7.5L5.47432 5.47432L7.5 0Z" fill="#7788BB"/>
                </svg>
                <div className="courses-card_text">Приобретение необходимых знаний для комфортного процесса передачи знаний групп любой численности.</div>
              </div>
            </div>
          </div>

        </div> 
        <h2 className="dark_header alt-course-header alt-header_2">Наша Планета именно сейчас, как никогда, <span>нуждается в сопровождении сильными Духом мастеров</span></h2>
        <p className="dark_text_about alt-course">Целителей, способных трансформировать программы прошлого в бесконечно созидающую энергию Любви и радости воплощения.</p>
        <p className="dark_text_about alt-course">Осознать самим и помочь людям взять ответственность за свою жизнь, будущее потомков и жизнь человечества на Планете Земля.</p>
        <div style={{cursor: 'pointer'}} onClick={() => showModalVideo()} className="player">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="26" viewBox="0 0 23 26" fill="none">
            <path d="M23 13L0.5 25.9904L0.5 0.00961876L23 13Z" fill="#9B9696"/>
          </svg>
        </div>
        <p className="player-text">Как будет проходить курс, об условии участии</p>
      </div>
    </section>
    </>
    );
};

export default observer(SMC);